<template>
    <neo-layout
        :loading="loading"
        :show-loader="false"
        height="fit-content"
    >
        <div class="entity-bar flex items-center mb-4 rounded-lg bg-white entity-toolbar w-full h-10">

            <div class="flex gap-3 pl-2 h-full items-center">
                <Info
                v-if="caseEntity?.id"
                :item="caseEntity"
                :readonly="readonly || caseEntity.readonly"
                :selected="caseEntity.selected"
                :disabled="caseEntity.disabled"
                :selectable="caseEntity.selectable"
                :class="{
                    'text-main hover:bg-primary-100': !caseEntity.selected,
                    'bg-main text-primary-content': caseEntity.selected
                }"
                @click="$emit('select', caseEntity)"
                />

                <Plus
                    v-if="showPlus"
                    :disabled="plusDisabled"
                    @click="$emit('add')"
                />
            </div>

            

            <!-- <Item
                v-if="caseEntity?.id"
                :item="caseEntity"
                :readonly="readonly || caseEntity.readonly"
                :selected="caseEntity.selected"
                :disabled="caseEntity.disabled"
                :selectable="caseEntity.selectable"
                class="entity-bar__case transition-all"
                :class="{
                    'text-main hover:bg-primary-100': !caseEntity.selected,
                    'bg-main text-primary-content': caseEntity.selected
                }"
                @remove="$emit('remove', caseEntity)"
                @select="$emit('select', caseEntity)"
            /> -->

            <div class="flex items-center w-full overflow-hidden gap-4 px-4">
                <div
                    v-if="entityItems?.length > 0"
                    class="rounded-lg w-9 h-full p-2 flex justify-center"
                    :class="{
                        'border-2 cursor-pointer transition-all hover:bg-primary-100': !arrowsDisabled,
                        'bg-gray-300 pointer-events-none': arrowsDisabled
                    }"
                    @click="scrollItems('left')"
                >
                    <font-awesome-icon
                        icon="chevron-left"
                        class="h-4"
                        :class="{
                            'text-main': !arrowsDisabled,
                            'bg-gray-300 text-gray-500 pointer-events-none': arrowsDisabled
                        }"
                    />
                </div>

                <div
                    v-if="entityItems?.length > 0"
                    ref="carousel"
                    class="flex items-center py-2 carousel w-full"
                >
                    <div ref="scrollableContent" class="flex items-center gap-4 scrollable-content">
                        <Item
                            v-for="item in entityItems"
                            :key="item.id"
                            :item="item"
                            class="entity-bar__item "
                            :class="{
                                'text-base-content': !item.selected,
                                'bg-main text-primary-content rounded-lg': item.selected
                            }"
                            :readonly="readonly || item.readonly"
                            :selected="item.selected"
                            :disabled="item.disabled"
                            :selectable="item.selectable"
                            :deletable="item.deletable"
                            :editable="editable"
                            @remove="$emit('remove', item)"
                            @select="$emit('select', item)"
                            :handleEntityChangeStatus="handleEntityChangeStatus"
                            @edit="$emit('edit', item)"
                        />
                    </div>
                </div>

                <div
                    v-if="entityItems?.length > 0"
                    class="rounded-lg w-9 h-full border-2 p-2 mr-2 flex justify-center"
                    :class="{
                        'border-2 cursor-pointer transition-all hover:bg-primary-100': !arrowsDisabled,
                        'bg-gray-300 pointer-events-none': arrowsDisabled
                    }"
                    @click="scrollItems('right')"
                >
                    <font-awesome-icon
                        icon="chevron-right"
                        class="h-4"
                        :class="{
                            'text-main': !arrowsDisabled,
                            'bg-gray-300 text-gray-500 pointer-events-none': arrowsDisabled
                        }"
                    />
                </div>
            </div>
        </div>
    </neo-layout>
</template>

<script>
import Item from "./EntityItem";
import Plus from "./Plus";
import Info from "./Info";
import NeoLayout from "@shared/components/neo-layout";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";

export default {
    name: "EntityToolBar",

    components: {
      Item,
      Plus,
      Info,
      NeoLayout 
    },

    data() {
        return {
            scrollAmount: 200, // Amount to scroll each click
            arrowsDisabled: false,
        };
    },

    props: {
        /**
         * @typedef {Object} Item
         * @property {number} id
         * @property {string} name
         */
        /**
         * @type {Item[]}
         */
        items: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        showPlus: {
            type: Boolean,
            default: true,
        },
        plusDisabled: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        
        handleEntityChangeStatus: {
            type: Function,
            default: () => {},
        },
    },

    mounted() {
        this.toggleArrows()
    },

    computed: {
        caseEntity() {
            return this.items?.find(item => item.type === ENTITY_TYPE_NAMES.CASE) ?? {};
        },
        entityItems() {
            return this.items?.filter(item => item.type !== ENTITY_TYPE_NAMES.CASE) ?? [];
        },
    },

    watch: {
        items: {
            handler() {
                this.toggleArrows();
            },
            deep: true,
        }
    },

    methods: {
        scrollItems(direction) {
            this.$refs?.scrollableContent.scrollBy({
                left: direction === "left" ? -this.scrollAmount : this.scrollAmount,
                behavior: 'smooth'
            });
        },

        toggleArrows() {
            this.arrowsDisabled = this.$refs?.scrollableContent?.clientWidth < this.$refs?.carousel?.clientWidth;
        }
    },
};
</script>

<style scoped lang="scss">
.scrollable-content {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
}
.scrollable-content::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
}

.entity-bar {
    &__case {
      border-left: 1px solid var(--theme-color-box-stroke);
      border-right: 1px solid var(--theme-color-box-stroke);
    }
}
</style>
