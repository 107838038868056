<template>
  <div
    v-if="dashboardId"
    id="dashboard_container"
  />
</template>

<script>
import VueCookies from "vue-cookies";
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";

export default {
  props: {
    dashboardId: {
      type: String,
    },
    alignError: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    dashboardId(val) {
      if (val) this.embedSample(val);
    },
  },

  async mounted() {
    if (this.dashboardId)
      this.embedSample(this.dashboardId);

    this.$nextTick(() => {
      document.addEventListener('click', this.handleDocClick);
    });
  },

  beforeDestroy() {    
    document.removeEventListener('click', this.handleDocClick);
  },

  methods: {
    embedSample(dashboardId) {
      let token = VueCookies.get("token");
      const boldBiHeader = {
        Authorization: "Bearer " + token,
      };

      var boldbiEmbedInstance = BoldBI.create({
        serverUrl: BoldBiConfig.BASE_URL,
        dashboardId: dashboardId,
        embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
        embedType: BoldBI.EmbedType.Component,
        environment: BoldBI.Environment.Enterprise,
        mode: BoldBI.Mode.View,
        height: "100%",
        width: "100%",
        authorizationServer: {
          url: biHelper.generateEmbedUrl(),
          headers: boldBiHeader,
        },
        expirationTime: "100000",
        onError: function() {
          const dashboardContainer = document.getElementById("dashboard_container");
          dashboardContainer.style.textAlign = "center"
          if (this.alignError) dashboardContainer.style.paddingTop = "20%"
          dashboardContainer.style.color = "red"
          dashboardContainer.innerHTML = "<h3>Error in loading dashboard, Please try again</h3>"
        },
      });
      boldbiEmbedInstance.loadDashboard();
    },
    handleDocClick(event) {
      if (event?.target?.id != 'dashboard_container_embeddedbi_otheroption' && !this.checkdropdownClick(event?.target)) {
        let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
        if (dropdownElement) {
          dropdownElement.style.display = 'none'
        }
      }
    },
    checkdropdownClick(docId) {
      let dropdownClicked = false;
      if (docId?.id == 'dashboard_container_embeddedbi_ConfigMenu' || docId?.parentElement.id == 'export') {
        dropdownClicked = true;
      }
      return dropdownClicked;
    },
    // Function to adjust the iframe height
    adjustIframeHeight() {
      var iframe = document.getElementById("dashboard-frame");
      if (iframe) {
        iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#dashboard_container {
  width: 100%;
  height: 100%;
}
#list {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(218, 218, 218, 0.87);
  background: #fff;
  margin-bottom: 12px;
}
::v-deep {
  #dashboard_container_embeddedbi_scrollContentDesignPanel,
  #dashboard_container_embeddedbi_bannerPanel
  {
    z-index: 0 !important;
  }
}
</style>